.header {
  .logo {
    padding-top: 5px;
    &__link {
      display: block;
    }
  }
  .row {
    height: 76px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #27292e;
  }
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    color: #d1d2d3;
  }
}
