.control {
  position: relative;
  height: 76px;
  padding: 0 40px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .control-btns {
    display: flex;
    align-items: center;

    .btn {
      padding: 0 6px;
    }

    .btn-search .icon {
      stroke: #a3a3a6;
      height: 23px;
      width: 23px;
    }
  }

  .separator {
    margin-top: -2px;
    padding: 0 6px;
    color: #393b41;
  }

  .icon-grid {
    stroke: #a3a3a6;
    fill: none !important;
  }

  .icon-dots,
  .icon-plus02 {
    fill: #a3a3a6;
    height: 18px;
    width: 18px;
  }
}
