.search {
  width: 100%;
  margin-left: 25px;
}

.search-form {
  position: relative;
  width: 100%;
  .icon {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 10px;
    left: 13px;
  }
  &__box {
    display: flex;
    align-items: center;
    position: relative;
    height: 36px;
    width: 100%;
    background: #212126;
    border: 1px solid #2d2e34;
    border-radius: 6px;
  }
  &__input {
    width: 100%;
    margin-left: 35px;
    margin-right: 70px;
    border: none;
    background: none;
    font-size: 14px;
  }
  &__input::placeholder {
    font-size: 14px;
    color: #696974;
  }
  &__btn {
    position: absolute;
    right: 0;
    padding: 5px 15px;
    font-size: 14px;
    text-align: right;
    color: #fefefe;
  }
}
