.dropdown-info {
  position: relative;
  height: 18px;
  .dropdown-box {
    left: 5px;
  }
}

.dropdown-box {
  display: none;
}

.dropdown-box_in {
  display: block;
}

.dropdown-box {
  width: 268px;
  position: absolute;
  top: 35px;
  background: #212126;
  border: 1px solid #37393f;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 111;
  padding-top: 8px;
  padding-bottom: 8px;
  &__title-sm {
    padding-left: 17px;
    padding-right: 17px;
    font-size: 12px;
    color: #7d7d86;
    margin-bottom: 3px;
  }
  &__line {
    margin-top: 7px;
    margin-bottom: 8px;
    display: block;
    height: 1px;
    width: 100%;
    background: #37393f;
  }
  &__item {
    width: 100%;
    display: inline-flex;
    align-items: center;
    margin: 2px 0;
    padding: 6px 17px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #d1d2d3;
    .icon {
      stroke: #a3a3a6;
      fill: none;
      height: 14px;
      width: 14px;
      margin-right: 10px;
    }
  }
  &__item.active,
  &__item:hover {
    opacity: 1;
    color: #fff;
    background: #51a4f3;
    .icon {
      stroke: #fff !important;
    }
  }
}

.dropdown-setting {
  position: absolute;
  top: 20px;
  right: 20px;
  .dropdown-box {
    position: absolute;
    top: 44px;
    right: 0;
  }
}
