button:active,
.nav-category__link:active {
  transform: translateY(-2px);
  transition: 0.1s;
}

button,
.nav-category__link {
  transform: translateY(0);
  transition: 0.1s;
  cursor: pointer;
}

.btn {
  cursor: pointer;
}

.btn:hover {
  opacity: 0.8;
}

/**** Button Round ****/

.btn-round {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #35363c;

  .icon {
    fill: #fff;
    height: 16px;
    width: 16px;
  }
}

.btn-icon {
  .icon {
    fill: none;
    height: 21px;
    width: 21px;
  }
}

.btn-hide {
  width: 46px;
  height: 30px;
  background: #212126;
  border: 1px solid #2d2e34;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 13px;
  text-align: center;
  color: #d1d2d3;
}

.btn-round:hover {
  background: #51a4f3;
  opacity: 1 !important;
}

.btn-round.active {
  background: #51a4f3;
}

.btn.active,
.btn-icon:hover,
.btn-icon:active {
  opacity: 1 !important;

  .icon {
    fill: #51a4f3;
  }

  .icon-search {
    stroke: #51a4f3 !important;
  }

  .icon-grid {
    stroke: #51a4f3;
  }
}
