.title-lg {
  font-family: $font02;
  font-size: 34px;
  line-height: 39px;
  color: #ffffff;
  font-weight: 400;
}

.title-m {
  font-family: Georgia;
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
}

.title-sm {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #d1d2d3;
}

.title-m02 {
  font-family: Georgia;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: -10px;
}
