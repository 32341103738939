* {
  margin: 0;
  padding: 0;
  outline: none !important;
}

body,
html {
  width: 100%;
  height: 100%;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

a,
a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
}

.nav > li > a:hover,
.nav > li > a:active,
.nav > li > a:focus {
  background: none;
}

.clearfix {
  clear: both;
}

.row {
  margin: 0;
}

button {
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
}
