* {
  box-sizing: border-box;
}

body {
  background: #14151a;
}

// a:hover {
//   opacity: 0.85;
// }

.container-fluid {
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #68696c;
  border-radius: 50px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #68696c;
}
