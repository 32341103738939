.cover:hover {
  .dropdown-setting {
    display: flex;
  }
}

.cover {
  position: relative;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  .dropdown-setting {
    display: none;
    .icon {
      fill: none !important;
    }
  }
  &__info {
    padding: 20px;
  }
  &__title {
    font-family: $font02;
    font-weight: 400;
    font-size: 24px;
    line-height: 110%;
    color: #ffffff;
  }
  &__subtitle {
    margin-top: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #d1d2d3;
  }
}

@media screen and (max-height: 820px) {
  .cover {
    height: 400px;
  }
}
