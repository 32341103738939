.article-popup {
  position: absolute;
  width: 268px;
  background: #202226;
  border: 1px solid #37393f;
  border-radius: 5px;
  z-index: 111;
  transform: translateY(-50%);
  &_in {
    display: block;
  }
  .arrow {
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    border: 15px solid transparent;
    border-right: 15px solid #37393f;
  }
  &__img {
    height: 166px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &__body {
    position: relative;
    margin: 16px;
    margin-bottom: 26px;
    max-height: 200px;
    overflow: hidden;
  }
  &__title {
    font-family: $font02;
    font-size: 18px;
    color: #ffffff;
  }
  &__text {
    * {
      margin: 0 !important;
      margin-top: 10px !important;
      font-size: 13px !important;
      line-height: 165% !important;
      color: #d1d2d3 !important;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -1px;
    right: -1px;
    height: 36px;
    width: 36px;
    background: #0e5278;
    .icon {
      width: 16px;
      height: 16px;
      fill: #fff;
    }
  }
}
