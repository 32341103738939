.main-sidebar {
  width: 0;
  overflow: hidden;
  // transition: width 0.3s;
  &.show {
    width: 280px;
    border-left: 1px solid #27292e;
  }
  &__head {
    padding: 0 20px;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #27292e;
  }

  &__menu:hover {
    overflow: auto;
  }

  &__menu {
    height: calc(100vh - 86px);
    overflow: hidden;
    margin-top: 5px;
    // margin-right: 5px;
    margin-bottom: 5px;
  }

  .page-nav {
    overflow-x: hidden;
  }
}
