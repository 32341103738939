.contents {
  margin-bottom: 30px;
  display: block !important;
}

.contents__head {
  display: flex;
  align-items: center;
  .title-m02 {
    margin-bottom: 0;
  }
  .btn-hide {
    margin-left: 12px;
  }
}

.contents__box.contents__box_in {
  display: block;
}

.contents__box {
  padding-top: 15px;
  padding-bottom: 15px;
  display: none;
  margin-top: 18px;
  max-width: 400px;
  background: #212327;
  border: 1px solid #353639;
  box-sizing: border-box;
  border-radius: 8px;
  ul {
    list-style: none;
  }
  .contents__item:last-child {
    border: none;
  }
  .contents__item {
    padding: 5px 18px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #d1d2d3;
    a {
      font-size: 14px;
      line-height: 17px;
      color: #51a4f3;
    }
  }
  .contents__sublist {
    padding-left: 20px;
  }
  .contents__subitem:last-child,
  .contents__subsubitem:last-child {
    margin-bottom: 0;
  }
  .contents__subitem {
    margin: 10px 0;
    font-size: 14px;
    line-height: 17px;
    color: #d1d2d3;
  }
  .contents__sublink {
    font-size: 14px;
    line-height: 17px;
    color: #51a4f3;
  }
  .contents__subsublist {
    padding-left: 25px;
  }
  .contents__subsubitem {
    margin: 10px 0;
    font-size: 14px;
    line-height: 17px;
    color: #d1d2d3;
  }
  .contents__subsublink {
    font-size: 14px;
    line-height: 17px;
  }
}
