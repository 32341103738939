.data__content,
.data__content:hover,
.data__content:focus,
.data:hover {
  visibility: visible !important;
}

.data {
  padding: 5px 15px 5px 20px;
  position: relative;
  visibility: hidden;
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: auto;
  height: calc(100vh - 510px);
  &__list_02 {
    border-top: 1px solid #353639;
    margin-top: 15px;
    padding-top: 15px;
  }
  &__item {
    padding: 10px 0;
  }
  &__title {
    display: inline-block;
    width: 35%;
    vertical-align: top;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #d0d2d4;
  }
  &__val {
    display: inline-block;
    width: 65%;
    margin-right: -3px;
    vertical-align: top;
    font-size: 12px;
    line-height: 150%;
    color: #d0d2d4;
  }
}

@media screen and (max-height: 820px) {
  .data {
    height: calc(100vh - 410px);
  }
}
