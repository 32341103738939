@tailwind base;
@tailwind components;
@tailwind utilities;

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #d1d2d3;
  pointer-events: none;
  font-size: 14px;
  height: 0;
}

.ProseMirror-focused p.is-editor-empty:first-child::before {
  content: '';
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  color: #ffffff;
  font-weight: 500;
}

.ProseMirror h1 {
  font-family: 'Georgia';
  font-size: 34px;
  line-height: 39px;
  padding-bottom: 8px;
  border-bottom: 1px solid #2d2e34;
  margin: 1rem 0;
  font-weight: 400;
}

.ProseMirror h2 {
  font-family: 'Georgia';
  font-size: 26px;
  line-height: 30px;
  margin-top: 12px;
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid #2d2e34;
}

.ProseMirror h3 {
  font-family: 'Georgia';
  margin-top: 8px;
  margin-bottom: -8px;
  font-size: 20px;
  font-weight: 700;
}

.ProseMirror h4 {
  font-family: 'Georgia';
  @apply text-lg;
}

.ProseMirror ul,
.ProseMirror ol {
  @apply pl-5;
}

.ProseMirror ul {
  @apply list-disc;
}

.ProseMirror ol {
  @apply list-decimal;
}

.ProseMirror .contents ul,
.ProseMirror .contents ol {
  @apply list-none;
}

.ProseMirror .contents__list {
  @apply pl-0 !important;
}

.ProseMirror .contents ol {
  counter-reset: outline;
}

.ProseMirror .contents ol li::before {
  content: counters(outline, '.') '. ';
  counter-increment: outline;
}

.ProseMirror blockquote {
  @apply border-l-4 border-gray-400 p-2;
}

.ProseMirror hr {
  @apply my-4 border-t-4 border-gray-200 opacity-10;
}

.ProseMirror p {
  margin: 22px 0;
  font-size: 14px;
  line-height: 180%;
  color: #d1d2d3;
}

.ProseMirror p b,
.ProseMirror p strong {
  font-size: inherit;
  font-weight: 700;
  color: inherit;
}

.ProseMirror p a {
  font-size: inherit;
  color: #51a4f3;
}

.ProseMirror blockquote p,
.ProseMirror ol p,
.ProseMirror ul p {
  margin: 0;
}
