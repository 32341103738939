.page-article {
  .row {
    display: flex;
  }
  .page-col {
    height: 100vh;
  }
  .page-col01 {
    background: $color-dark01;
  }

  .page-col01.main-sidebar-in {
    min-width: 99px;
    max-width: 99px;
    background: $color-dark01;
  }

  .page-col02 {
    border: 1px solid #27292e;
    box-sizing: border-box;
    border-top-left-radius: 20px;
    width: 100%;
    background: $color-dark02;
  }
  .page-col03 {
    max-width: 335px;
    min-width: 335px;
    background: $color-dark03;
  }
  .page-nav {
    position: relative;
    padding-top: 6px;
    padding-bottom: 30px;
    height: 100%;
    .title {
      margin: 25px 0;
      margin-bottom: 20px;
    }
  }
  .content {
    position: relative;
    padding: 0 40px;
    padding-top: 30px;
    height: calc(100vh - 78px);
    overflow: auto;
    visibility: hidden;
    margin-right: 3px;
  }
  .content__wrap,
  .content__wrap:hover,
  .content__wrap:focus,
  .content:hover {
    visibility: visible !important;
  }
  // .content:after{
  //     position: fixed;
  //     bottom: 0;
  //     left: 382px;
  //     right: 336px;
  //     height: 77px;
  //     content: "";
  //     display: block;
  //     background: linear-gradient(180deg, rgba(27, 28, 33, 0.29) 0%, #1B1C21 100%);
  // }
  .blur {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    content: '';
    display: block;
    background: rgba(21, 21, 25, 0.4);
    backdrop-filter: blur(3px);
    z-index: 2;
    visibility: hidden;
    transition: 0.2s;
    overflow: hidden;
  }
  .blur.in {
    visibility: visible;
    transition: 0.2s;
  }
}

.content-two-col {
  display: flex;
  .text {
    p:first-child {
      margin-top: 0;
    }
  }
}

.img-box {
  margin-top: 2px;
  margin-bottom: 30px;
  background: #202226;
  border: 1px solid #37393f;
  box-sizing: border-box;
  border-radius: 6px;
  width: 176px;
  margin-left: 40px;
  flex-shrink: 0;
  cursor: pointer;
  &__title {
    padding: 10px;
    font-size: 10px;
    line-height: 140%;
    color: #d1d2d3;
  }
  &__img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 100px;
    background-size: cover;
    background-position: center;
  }
}
