.preloader {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #0e0f10;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	z-index: 9999; 
	img{
		margin: auto;
		margin-bottom: 10px;
	}
	div{
		text-align: center;
	}
	.loading-bar {
		display: flex;
		height: 4px;
		width: 180px;
		background: #2f3235;
		border-radius: 50px;
		overflow: hidden;
		
		@keyframes progress-animation {
			0% {width: 0%;}
			20% {width: 10%;}
			40% {width: 30%;}
			50% {width: 60%;}
			100% {width: 90%;}
		}

		.progress-bar {
			display: flex;
			height: 100%;
			width: 90%;
			background: #fff;
			animation: progress-animation 3s ease-in-out;
		}
	}
}