.nav {
  &__list {
    list-style: none;
  }

  a:hover {
    opacity: 1 !important;
  }

  &__item_active {
    .icon-caret {
      transform: rotate(0deg) !important;
      transition: 0.2s;
    }
    .nav__link {
      // background: #202226;
      // border: 1px solid #2D2E34;
      color: #51a4f3;

      .icon-folder {
        display: none;
      }

      .icon-folder02 {
        display: block;
      }

      .icon-dots {
        fill: #a3a3a6;
        height: 14px;
        width: 14px;
      }

      .icon-dots:hover {
        fill: #51a4f3;
      }
    }
  }

  &__item:not(.nav__item_active):hover {
    .nav__link:hover {
      // background: #51A4F3;
      padding-left: 12px;
      padding-right: 12px;

      .icon {
        stroke: #fff;
      }
      & > .dropdown-info {
        .icon-dots {
          stroke: none !important;
          fill: #fff !important;
        }
      }
      & > .dropdown-info {
        display: block;
      }
    }
  }
  &__item:hover {
    & > .dropdown-info {
      display: block;
    }
    & > .dropdown-info {
      .icon-dots {
        stroke: none !important;
        fill: #fff !important;
      }
    }
  }
  &__item {
    .icon-caret {
      fill: #a3a3a6 !important;
      stroke: none !important;
      height: 8px !important;
      width: 10px !important;
      transform: rotate(-90deg);
      transition: 0.2s;
    }
    position: relative;

    & > .dropdown-info.active {
      display: block !important;
      z-index: 1555;
    }
    & > .dropdown-info {
      display: none;
      top: 6px;
      position: absolute;
      right: 0;
      z-index: 1111;
      .dropdown-box {
        width: 220px;
        left: auto;
        top: 30px;
        right: 13px;
        z-index: 1333 !important;
      }
      .btn {
        z-index: 1111 !important;
      }
      .icon-del03 {
        height: 15px;
        width: 15px;
      }
      .icon-dots:hover {
        fill: #51a4f3 !important;
      }
      .btn-icon.active {
        .icon-dots {
          fill: #51a4f3 !important;
        }
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 6px 8px;
    padding-left: 12px;
    padding-right: 12px;

    .icon-folder {
      display: block;
      stroke: #a3a3a6;
    }

    .icon-folder02 {
      display: none;
    }

    .icon-dots {
      position: absolute;
      right: 10px;
      top: 7px;
      margin-right: 0 !important;
    }
  }

  .icon {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    fill: none;
  }

  .icon-dots {
    fill: #fff;
  }

  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #35363b;
    border-radius: 4px;
    margin-right: 11px;

    .icon {
      height: 12px;
      width: 12px;
      margin-right: 0;
    }
  }
}

.nav-submenu.in {
  display: block;
}

.nav-submenu {
  display: none;
  padding-left: 28px;
  list-style: none;

  &__link {
    width: 100%;
    padding: 5px 7px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #d1d2d3;
    .icon-document {
      stroke: #d1d2d3;
      fill: none !important;
    }
    // .icon-hash {
    //     height: 12px !important;
    //     width: 18px !important;
    //     stroke: none !important;
    //     fill: #A3A3A6 !important;
    // }
  }

  &__item {
    position: relative;

    & > .dropdown-info.active {
      display: block !important;
      z-index: 1555;
    }
    & > .dropdown-info {
      display: none;
      top: 4px;
      position: absolute;
      right: 0;
      z-index: 1111;
      .btn {
        z-index: 1111 !important;
      }
      .icon-del03 {
        height: 15px;
        width: 15px;
      }
      .icon-dots:hover {
        fill: #fff !important;
      }
      .btn-icon.active {
        .icon-dots {
          fill: #fff !important;
        }
      }
    }
  }

  &__item:hover {
    & > .dropdown-info {
      display: block;
    }
    
    .nav-submenu__link {
      position: relative;
      background: #27292e;
      z-index: 3;
      color: #fff;

      // .icon {
      //     stroke: none !important;
      //     fill: #fff !important;
      // }

      .icon-document {
        stroke: #fff !important;
        fill: none !important;
      }
    }

    .nav-submenu__link:before {
      content: '';
      position: absolute;
      left: -58px;
      right: calc(-12px - 5px);
      background: #51a4f3;
      display: block;
      height: 100%;
      z-index: -1;
    }
  }

  &__item_active {
    .nav-submenu__link {
      position: relative;
      background: #27292e;
      z-index: 3;

      .icon {
        stroke: #61b2ff;
      }
    }

    .nav-submenu__link:before {
      content: '';
      position: absolute;
      left: -58px;
      right: calc(-12px - 5px);
      background: #27292e;
      display: block;
      height: 100%;
      z-index: -1;
    }
  }
}

.nav-category__content,
.nav-category__content:hover,
.nav-category__content:focus,
.nav-category:hover {
  visibility: visible !important;
}

.nav-category {
  height: calc(100vh - 86px);
  overflow: auto;
  visibility: hidden;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 3px;

  &__content {
    width: 100%;
    height: calc(100vh - 86px);
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }

  &__list {
    width: 100%;
    list-style: none;
  }

  &__item_active {
    a {
      border-left: 2px solid #2b71fa;
      background: linear-gradient(
        270deg,
        rgba(81, 164, 243, 0) 0%,
        rgba(81, 164, 243, 0.4) 100%
      );
    }
    .nav-category__link:hover {
      opacity: 1 !important;
      span {
        color: #fff;
      }
      .icon {
        stroke: #fff;
      }
      .icon.icon-search {
        stroke: none !important;
        fill: #fff !important;
      }
    }
  }

  &__link:hover {
    opacity: 1 !important;
    span {
      color: #51a4f3;
    }
    .icon {
      stroke: #51a4f3;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 7px;
    .icon {
      width: 25px;
      height: 25px;
      fill: none;
      stroke: #fff;
    }
    img {
      max-width: 30px;
      max-height: 30px;
      margin: auto;
    }

    span {
      margin-top: 10px;
      display: block;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      text-align: center;
      color: #ffffff;
    }
  }

  &__list_02 {
    margin-top: 20px;
  }
}

@media screen and (max-height: 820px) {
  .nav-category__link img {
    max-width: 20px;
    max-height: 20px;
  }

  .nav-category__link {
    padding: 12px 7px;
  }
}

@media screen and (min-height: 820px) {
  .nav-category {
    &__list_02 {
      margin-top: 150px;
    }
  }
}
