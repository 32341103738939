.link-popup {
  display: inline-block;
}

.text {
  p {
    margin: 25px 0;
    font-size: 14px;
    line-height: 180%;
    color: #d1d2d3;
    b {
      font-size: 14px;
      font-weight: 700;
      color: #d1d2d3;
    }
    a {
      font-size: 14px;
      color: #51a4f3;
    }
  }
}

.subtitle {
  margin-top: 13px;
  font-size: 12px;
  line-height: 15px;
  color: #d1d2d3;
}

.custom-border {
  margin-top: 12px;
  display: block;
  height: 1px;
  width: 100%;
  background: #2d2e34;
}
