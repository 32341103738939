.chat-popup.in {
  display: block;
}

.chat-popup {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1998;
  .chat-popup-close {
    position: absolute;
    right: 25px;
    top: 23px;
    background: none;
    border: none;
    .icon {
      width: 14px;
      height: 14px;
      fill: #989ba0;
    }
  }
  .chat-popup-close:hover,
  .chat-popup-close:active {
    opacity: 1;
    .icon {
      fill: $color-paint;
    }
  }
  .chat-popup-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(215, 222, 228, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chat-popup-box {
    position: relative;
    width: 520px;
    background: #ffffff;
    border-radius: 8px;
  }
  .chat-popup-head {
    padding: 23px;
    padding-right: 60px;
    padding-bottom: 20px;
    .chat-popup-title {
      font-size: 20px;
      line-height: 100%;
      color: #000000;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .chat-popup-subtitle {
      font-size: 14px;
      line-height: 14px;
      color: #131313;
    }
  }
  .user-message-box {
    margin-top: 0;
    background: #f8f8f8 !important;
    padding: 16px 23px;
    padding-right: 50px;
  }
  .user-emotions .emotion01 {
    background-color: #ffffff;
  }
  .chat-popup-footer {
    display: flex;
    padding: 20px 25px;
    justify-content: flex-end;
    button {
      display: inline-flex;
    }
  }
  button:last-child {
    margin-left: 7px;
  }
  .button-color {
    border-color: #ea3363;
    background: #ea3363;
  }
  .button-color:hover {
    color: #ea3363;
    background: none;
    opacity: 1;
  }
}

.chat-popup-search {
  display: none;
  .search-input-box {
    display: flex;
    align-items: center;
    padding: 21px 23px;
    height: 20px;
    .search-placeholder {
      display: none;
      transition: 0.2s;
      .icon-search {
        stroke: #525252;
        height: 18px;
        width: 18px;
      }
      span {
        display: inline-block;
        margin-left: 10px;
        font-size: 18px;
        line-height: 18px;
        color: #7d7d7d;
      }
    }
    .search-placeholder.active {
      display: flex;
      align-items: center;
      transition: 0.2s;
    }
  }
  .search-input {
    background: none;
    display: none;
    height: 20px;
    min-width: 100%;
    border: none;
    font-size: 18px;
    line-height: 18px;
    color: #7d7d7d;
    max-width: 300px;
  }
}

.chat-popup-remind {
  .chat-popup-wrap {
    background: rgba(22, 23, 24, 0.35);
  }
  .chat-popup-box {
    background: #212327;
  }
  .chat-remind-head {
    padding: 21px 23px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .popup-title {
      font-weight: 700;
      font-size: 20px;
      color: #fff;
    }
  }
  .search-input-box .search-placeholder span {
    display: inline-block;
    margin-left: 10px;
    font-size: 15px;
    color: #94979b;
  }
  .search-input-box .search-placeholder .icon-search {
    fill: #94979b;
    stroke: transparent;
    height: 18px;
    width: 18px;
  }
  .search-input-box {
    padding-top: 5px;
    padding-bottom: 15px;
  }
  .chat-remind-body {
    height: 320px;
    overflow: auto;
  }
  .chat-remind-list {
    list-style: none;
    padding-top: 8px;
    padding-bottom: 15px;
    box-sizing: border-box;
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 23px;
      cursor: pointer;
      box-sizing: border-box;
      .date-text {
        font-size: 15px;
        line-height: 15px;
        color: #94979b;
      }
      .date-num {
        font-size: 15px;
        color: #94979b;
      }
    }
    a.active {
      background: #3f4147;
      * {
        color: #fff;
      }
      .date-text {
        font-weight: 500;
      }
    }
    a:hover {
      background: #36383e;
    }
  }
}
