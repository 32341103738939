$font01: 'Inter';
$font02: 'Georgia';

$font-size: 16px;

* {
  font-size: $font-size;
  color: $color-main;
  font-weight: 400;
  font-family: $font01;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: Georgia;
  src: url(/assets/fonts/Georgia.woff);
  font-weight: 400;
}

@font-face {
  font-family: Georgia;
  src: url(/assets/fonts/Georgia-Bold.woff);
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url(/assets/fonts/Inter-Medium.woff);
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url(/assets/fonts/Inter-Bold.woff);
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url(/assets/fonts/Inter-Medium.woff);
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url(/assets/fonts/Inter-Regular.woff);
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url(/assets/fonts/Inter-Light.woff);
  font-weight: 300;
}
