$color-main: #fff;
$color-paint: #69a9ff;

$color-dark01: #14151a;
$color-dark02: #1b1c21;
$color-dark03: #212327;

//a,
//a:hover,
//a:visited,
//a:active,
//a:link {
//    color: $color-main;
//}
